import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export type CommonState = {
    currentRouteKey: string
    isVerifyPopupOpen: boolean
    isUpgradeModalOpen: boolean
}

export const initialState: CommonState = {
    currentRouteKey: '',
    isVerifyPopupOpen: false,
    isUpgradeModalOpen: false,
}

export const commonSlice = createSlice({
    name: `${SLICE_BASE_NAME}/common`,
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action: PayloadAction<string>) => {
            state.currentRouteKey = action.payload
        },

        setVerifyPopupState: (state, action: PayloadAction<boolean>) => {
            state.isVerifyPopupOpen = action.payload
        },

        setUpgradeModalState: (state, action: PayloadAction<boolean>) => {
            state.isUpgradeModalOpen = action.payload
        },
    },
})

export const { setCurrentRouteKey, setUpgradeModalState, setVerifyPopupState } =
    commonSlice.actions

export default commonSlice.reducer
