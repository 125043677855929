import {
    apiCreatePassword,
    apiRefreshToken,
    apiResetPassword,
    apiSignIn,
    apiSignUp,
    apiVerifyLogin,
} from '@/services/AuthService'
import {
    signInSuccess,
    signOutSuccess,
    useAppSelector,
    useAppDispatch,
    refreshTokenSuccess,
    setUserUidb,
    setUserToken,
    setUserInfo,
    setVerifyPopupState,
} from '@/store'
import appConfig from '@/configs/app.config'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import type {
    RefreshToken,
    ResetPassword,
    SignInCredential,
    SignUpCredential,
    VerifyLogin,
} from '@/@types/auth'
import { decodeJWT } from '../decodJWT'
import Notify from '../Notify'
import { AxiosError } from 'axios'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { AUTH_PREFIX_PATH } from '@/constants/route.constant'

function useAuth() {
    const dispatch = useAppDispatch()

    const navigate = useNavigate()
    const [URLSearchParams] = useSearchParams()

    const { search } = useLocation()

    const redirect_path = URLSearchParams.get(REDIRECT_URL_KEY)

    const { access, isLoggedIn } = useAppSelector((state) => state.auth.session)

    const signUp = async (values: SignUpCredential) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.status === 201) {
                navigate(`${AUTH_PREFIX_PATH}/email-confirm`, {
                    replace: true,
                })
            }
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        } catch (errors: any) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signIn = async (values: SignInCredential) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.status === 200) {
                if (resp.data.uidb) {
                    navigate(
                        `${AUTH_PREFIX_PATH}/2fa-login/${resp.data.uidb}` +
                            search,
                    )
                }
            }
            return resp
        } catch (err) {
            if (err instanceof AxiosError) {
                const data = err.response?.data
                const uidb = data.uidb as string
                if (data['detail']) {
                    Notify({
                        type: 'danger',
                        title: `${data['detail']}`,
                    })
                }

                dispatch(
                    setUserUidb({
                        uidb: uidb,
                    }),
                )
                if (data['needEmailVerification']) {
                    dispatch(setVerifyPopupState(true))
                } else if (data['firstTimeLogin']) {
                    dispatch(
                        setUserToken({
                            token: data.token,
                        }),
                    )
                    navigate(
                        `${AUTH_PREFIX_PATH}/reset-password/${data?.uidb}/${data?.token}` +
                            search,
                        {
                            replace: true,
                        },
                    )
                } else if (data['needActivate2fA']) {
                    navigate(
                        `${AUTH_PREFIX_PATH}/2fa-login/${data?.uidb}` + search,
                        {
                            state: data,
                        },
                    )
                }
            }
        }
    }

    const verifyLogin = async (values: VerifyLogin) => {
        try {
            const resp = await apiVerifyLogin(values)
            if (resp.status === 200) {
                const { access, refresh } = resp.data

                const decoded = decodeJWT(access)

                if (decoded) {
                    const {
                        payload: {
                            fullName,
                            email,
                            user_id,
                            hasTourTaken,
                            company,
                            role,
                        },
                    } = decoded
                    dispatch(
                        setUserInfo({
                            user_id: user_id,
                            email: email,
                            userName: fullName,
                            company: company,
                            role: role,
                            hasTourTaken: hasTourTaken,
                        }),
                    )
                }

                dispatch(
                    signInSuccess({
                        access: access,
                        refresh: refresh,
                    }),
                )
                navigate(search ? `${redirect_path}` : appConfig.wizardPath, {
                    replace: true,
                })
            }
        } catch (err) {
            if (err instanceof AxiosError) {
                const error = err?.response?.data
                const message = Object.values(error)[0]
                Notify({ type: 'warning', title: `${message}` })
            }
            return err
        }
    }

    const createPassword = async (
        values: ResetPassword,
        {
            uidb,
            token,
        }: {
            uidb: string
            token: string
        },
    ) => {
        try {
            const resp = await apiCreatePassword(values, { uidb, token })

            if (resp.status === 200) {
                navigate(appConfig.unAuthenticatedEntryPath + search)
                Notify({
                    title: 'Success',
                    message: 'Your password has been successfully reset',
                })
            }
        } catch (err) {
            if (err instanceof AxiosError) {
                const data = err.response?.data
                if (data['detail']) {
                    Notify({
                        type: 'info',
                        title: `${data['detail']}`,
                    })
                }
            }
        }
    }

    const resetPassword = async (
        values: ResetPassword,
        {
            uidb,
            token,
        }: {
            uidb: string
            token: string
        },
    ) => {
        const resp = await apiResetPassword(values, { uidb, token })

        if (resp.status === 200) {
            navigate(appConfig.unAuthenticatedEntryPath + search)
            Notify({
                title: 'Success',
                message: 'Your password has been successfully reset',
            })
        }
    }

    const signOut = () => {
        dispatch(signOutSuccess())
        navigate(appConfig.unAuthenticatedEntryPath, {
            replace: true,
        })
    }

    const refreshToken = async (values: RefreshToken) => {
        const resp = await apiRefreshToken(values)
        if (resp.data) {
            const tokens = resp.data
            dispatch(refreshTokenSuccess(tokens))
        }
    }

    return {
        authenticated: access && isLoggedIn,
        signUp,
        signIn,
        verifyLogin,
        signOut,
        refreshToken,
        createPassword,
        resetPassword,
    }
}

export default useAuth
