/* eslint-disable @typescript-eslint/no-explicit-any */
interface JWTHeader {
    alg: string
    typ: string
    [key: string]: any // Allow for any additional properties
}

interface JWTPayload {
    sub?: string
    aud?: string
    exp?: number
    iat?: number
    [key: string]: any // Allow for any additional properties
}

interface DecodedJWT {
    header: JWTHeader
    payload: JWTPayload
}

export function decodeJWT(token: string): DecodedJWT | null {
    try {
        // JWT tokens are in the format: Header.Payload.Signature
        const [headerEncoded, payloadEncoded] = token.split('.')

        // Base64 decode and parse JSON
        const header: JWTHeader = JSON.parse(atob(headerEncoded))
        const payload: JWTPayload = JSON.parse(atob(payloadEncoded))

        return {
            header,
            payload,
        }
    } catch (e) {
        console.error('Failed to decode JWT:', e)
        return null
    }
}
