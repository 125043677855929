import { AxiosResponse } from 'axios'

import ApiService from './ApiService'
import type {
    SignInCredential,
    ForgotPassword,
    ResetPassword,
    SignInResponse,
    RefreshToken,
    VerifyLogin,
    VerifyLoginResponse,
    PasswordFormModel,
    SignUpCredential,
} from '@/@types/auth'

export async function apiSignUp(data: SignUpCredential) {
    return ApiService.fetchData<SignInResponse>({
        url: '/registration/',
        method: 'post',
        data,
    })
}

export async function apiSignIn(data: SignInCredential) {
    return ApiService.fetchData<SignInResponse>({
        url: '/login/',
        method: 'POST',
        data,
    })
}

export async function apiForgotPassword(data: ForgotPassword) {
    return ApiService.fetchData({
        url: 'base/forgot-password/',
        method: 'POST',
        data,
    })
}

export async function apiChangePassword(
    data: PasswordFormModel,
    user_id: number,
) {
    return ApiService.fetchData({
        url: `/company/users/${user_id}/change-password/`,
        method: 'PUT',
        data,
    })
}

export async function apiResetPassword(
    data: ResetPassword,
    {
        uidb,
        token,
    }: {
        uidb: string
        token: string
    },
) {
    return ApiService.fetchData({
        url: `/base/reset-password/${uidb}/${token}/`,
        method: 'put',
        data,
    })
}

export async function apiCreatePassword(
    data: ResetPassword,
    {
        uidb,
        token,
    }: {
        uidb: string
        token: string
    },
) {
    return ApiService.fetchData({
        url: `/base/create-password/${uidb}/${token}/`,
        method: 'put',
        data,
    })
}

export const apiRefreshToken = async (
    data: RefreshToken,
): Promise<AxiosResponse> => {
    return ApiService.fetchData({
        url: '/token/refresh/',
        method: 'POST',
        data: {
            refresh: data,
        },
    })
}

export async function apiVerifyLogin(data: VerifyLogin) {
    return ApiService.fetchData<VerifyLoginResponse>({
        url: '/login/verify/',
        method: 'POST',
        data,
    })
}

export const requestnewEmailVerification = async (uidb: string) => {
    await ApiService.fetchData({
        method: 'POST',
        url: `/base/email-verification/${uidb}/`,
    })
}
