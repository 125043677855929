import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import useHotjar from 'react-use-hotjar'

import store, { persistor } from './store'
import Theme from '@/components/template/Theme'
import Layout from '@/components/layouts'
import './locales'

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnMount: true,
                refetchOnWindowFocus: false,
            },
        },
    })
    const { initHotjar } = useHotjar()

    const DEV_ENV = import.meta.env.DEV
    useEffect(() => {
        if (!DEV_ENV) {
            const siteId = import.meta.env.VITE_PUBLIC_HOTJAR_SITE_ID as number
            const hotjarVersion = import.meta.env
                .VITE_PUBLIC_HOTJAR_VERSION as number
            initHotjar(siteId, hotjarVersion, false)
        }
    }, [initHotjar, DEV_ENV])
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <Theme>
                            <Layout />
                        </Theme>
                    </QueryClientProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default App
