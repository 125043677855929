import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'
import { CustomOption, ICompanyProps } from '@/@types/common'

export type UserState = {
    role: CustomOption<number>
    uidb?: string
    token?: string
    userName?: string
    email?: string
    user_id?: number
    hasTourTaken?: boolean
    company: ICompanyProps
}

const initialState: UserState = {
    uidb: '',
    token: '',
    userName: '',
    email: '',
    role: {
        label: 'Member',
        value: 2,
    },
    hasTourTaken: false,
    company: {
        id: '',
        name: '',
        logo: '',
        tier: {
            type: {
                label: '',
                value: 'fr',
            },
            description: '',
            price: '',
            url: '',
            features: [],
        },
        isOnBoarded: false,
    },
}

const userSlice = createSlice({
    name: `${SLICE_BASE_NAME}/user`,
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<UserState>) => {
            return {
                ...state,
                company: action.payload.company,
                email: action.payload?.email,
                userName: action.payload?.userName,
                role: action.payload?.role,
                user_id: action.payload?.user_id,
                hasTourTaken: action.payload?.hasTourTaken,
            }
        },
        setUserUidb: (
            state,
            action: PayloadAction<Pick<UserState, 'uidb'>>,
        ) => {
            return {
                ...state,
                uidb: action.payload?.uidb,
            }
        },
        setUserToken: (
            state,
            action: PayloadAction<Pick<UserState, 'token'>>,
        ) => {
            return {
                ...state,
                token: action.payload?.token,
            }
        },
        resetUserData: () => {
            return initialState
        },
    },
})

export const { setUserInfo, setUserUidb, setUserToken, resetUserData } =
    userSlice.actions
export default userSlice.reducer
