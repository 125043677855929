import { useState, useEffect, useRef, forwardRef } from 'react'
import useMergedRef from '../hooks/useMergeRef'
import classNames from 'classnames'
import type { CommonProps, TypeAttributes } from '../@types/common'
import type { ReactNode } from 'react'
import NameAvatar from 'react-avatar'

export interface AvatarProps extends CommonProps {
    alt?: string
    icon?: ReactNode
    onClick?: () => void
    size?: 'lg' | 'md' | 'sm' | number
    shape?: Exclude<TypeAttributes.Shape, 'none'> | 'square'
    src?: string
    srcSet?: string
}

const Avatar = forwardRef<HTMLSpanElement, AvatarProps>((props, ref) => {
    const {
        alt,
        className,
        icon,
        shape = 'rounded',
        size = 'md',
        src,
        srcSet,
        ...rest
    } = props

    const [scale, setScale] = useState(1)
    const avatarChildren = useRef<HTMLSpanElement>(null)
    const avatarNode = useRef<HTMLSpanElement>(null)
    const avatarMergeRef = useMergedRef(ref, avatarNode)

    const calculateScale = () => {
        if (!avatarChildren.current || !avatarNode.current) return
        const avatarChildrenWidth = avatarChildren.current.offsetWidth
        const avatarNodeWidth = avatarNode.current.offsetWidth
        if (avatarChildrenWidth === 0 || avatarNodeWidth === 0) return

        setScale(
            avatarNodeWidth - 8 < avatarChildrenWidth
                ? (avatarNodeWidth - 8) / avatarChildrenWidth
                : 1,
        )
    }

    useEffect(() => {
        calculateScale()
    }, [scale, props.children])

    const getSizeStyle = () =>
        typeof size === 'number'
            ? {
                  width: size,
                  height: size,
                  minWidth: size,
                  lineHeight: `${size}px`,
                  fontSize: icon ? size / 2 : 12,
              }
            : {}

    const getClasses = () =>
        classNames(
            'avatar',
            `avatar-${shape}`,
            typeof size === 'string' ? `avatar-${size}` : '',
            className,
        )

    const renderChildren = () => {
        if (src || alt) {
            return src && alt ? (
                <img
                    className={`avatar-img avatar-${shape}`}
                    src={src}
                    srcSet={srcSet}
                    alt={alt}
                    loading="lazy"
                />
            ) : (
                <NameAvatar
                    name={alt}
                    size="100%"
                    round
                    className="cursor-pointer [&>*]:!text-xs"
                />
            )
        } else if (icon) {
            return (
                <span
                    className={classNames('avatar-icon', `avatar-icon-${size}`)}
                >
                    {icon}
                </span>
            )
        } else {
            const childrenSizeStyle =
                typeof size === 'number' ? { lineHeight: `${size}px` } : {}
            const stringCentralized = {
                transform: `translateX(-50%) scale(${scale})`,
            }
            return (
                <span
                    ref={avatarChildren}
                    className={`avatar-string ${
                        typeof size === 'number' ? '' : `avatar-inner-${size}`
                    }`}
                    style={{
                        ...childrenSizeStyle,
                        ...stringCentralized,
                        ...(typeof size === 'number' ? { height: size } : {}),
                    }}
                >
                    {props.children}
                </span>
            )
        }
    }

    return (
        <span
            ref={avatarMergeRef}
            className={getClasses()}
            style={{ ...getSizeStyle(), ...rest.style }}
            {...rest}
        >
            {renderChildren()}
        </span>
    )
})

Avatar.displayName = 'Avatar'

export default Avatar
