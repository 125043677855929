import axios, { AxiosError } from 'axios'
import appConfig from '@/configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '@/constants/api.constant'
import { PERSIST_STORE_NAME } from '@/constants/app.constant'
import deepParseJson from '@/utils/deepParseJson'
import store, { refreshTokenSuccess, signOutSuccess } from '../store'
import { apiRefreshToken } from './AuthService'

const unauthorizedCode = [403]
let isRefreshing = false

const BaseService = axios.create({
    timeout: 60000,
    baseURL: import.meta.env.VITE_BACKEND_BASE_URL + appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let accessToken = (persistData as any).auth.session.access

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.access
        }

        if (accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] =
                `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(new Error(error))
    },
)

BaseService.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        const { response, config } = error
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const refresh = (persistData as any).auth.session.refresh

        if (response?.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true
                try {
                    const resp = await apiRefreshToken(refresh)
                    store.dispatch(refreshTokenSuccess(resp.data))
                } catch (err) {
                    store.dispatch(signOutSuccess())
                    return Promise.reject(error)
                } finally {
                    isRefreshing = false
                }
                return axios(config!)
            }
        }

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(signOutSuccess())
        }

        return Promise.reject(error)
    },
)

export default BaseService
