import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export interface TokensState {
    access: string | null
    refresh: string | null
}

export interface SessionState extends TokensState {
    isLoggedIn: boolean
}

const initialState: SessionState = {
    isLoggedIn: false,
    access: null,
    refresh: null,
}

const sessionSlice = createSlice({
    name: `${SLICE_BASE_NAME}/session`,
    initialState,
    reducers: {
        signInSuccess(state, action: PayloadAction<TokensState>) {
            state.access = action.payload.access
            state.refresh = action.payload.refresh
            state.isLoggedIn = true
        },

        signOutSuccess(state) {
            state.isLoggedIn = false
            state.access = null
            state.refresh = null
        },
        refreshTokenSuccess(state, action: PayloadAction<TokensState>) {
            state.access = action.payload.access
            state.refresh = action.payload.refresh
        },
    },
})

export const { signInSuccess, refreshTokenSuccess, signOutSuccess } =
    sessionSlice.actions
export default sessionSlice.reducer
